'use client'

import type {
  TestimonialCarouselItemStoryblok,
  TestimonialCarouselStoryblok,
} from '@vendure/codegen/storyblok'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CustomImage, GradientCard } from '@vendure/components/shared/client'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

interface ITestimonialCarousel {
  blok: TestimonialCarouselStoryblok
}

function renderItem(item: TestimonialCarouselItemStoryblok) {
  return (
    <div className="relative flex w-full grid-cols-1 flex-col justify-center py-[40px] md:flex-row md:gap-12 md:px-[60px] md:py-[100px]">
      {item.image?.filename && (
        <div className="z-0 order-1 max-h-[600px] overflow-hidden rounded-md md:order-2 md:mx-20 md:translate-x-[200px] md:translate-y-[-50px]">
          <CustomImage
            width={2000}
            height={1000}
            className="h-[300px] w-full object-cover object-top md:h-[600px] md:w-[900px]"
            src={item.image.filename}
            alt={item.image.alt ?? ''}
          />
        </div>
      )}
      <GradientCard
        outerProps={{
          className:
            'max-w-[650px] mx-4 md:mx-0 -translate-y-10 order-2 md:order-1 shadow-lg md:absolute md:bottom-20 md:left-50 md:translate-x-[-50%] h-auto z-10 ',
        }}
        containerProps={{
          className: 'p-8 bg-slate-900',
        }}
      >
        <div className="flex flex-col items-start justify-center gap-6 md:gap-8">
          {item.logo?.filename && (
            <CustomImage
              alt={item.clientName ?? ''}
              src={item.logo.filename}
              width={400}
              height={200}
              className="h-[40px] w-auto"
              style={{
                filter: 'brightness(0) invert(1)',
              }}
            />
          )}
          <p className="font-light leading-relaxed text-white md:text-lg lg:text-2xl">
            {item.testimonial}
          </p>
          <div className="flex flex-col">
            <span className="text-lg font-semibold text-white">
              {item.name}
            </span>
            <span>{item.clientName}</span>
          </div>
        </div>
      </GradientCard>
    </div>
  )
}

export function TestimonialCarousel({ blok }: ITestimonialCarousel) {
  function renderItems() {
    return blok.items?.map((item) => {
      return <SwiperSlide key={item._uid}>{renderItem(item)}</SwiperSlide>
    })
  }

  return (
    <Swiper
      modules={[Pagination, Navigation]}
      slidesPerView={1}
      pagination={{ clickable: true }}
    >
      {renderItems()}
    </Swiper>
  )
}
