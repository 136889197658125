'use client'

import type {
  ArticleCategoryStoryblok,
  ArticleStoryblok,
  PersonStoryblok,
} from '@vendure/codegen/storyblok'
import {
  CustomImage,
  CustomLink,
  FormattedDate,
  GradientCard,
  Heading,
  HorizontalPersonCard,
  PreTitle,
  RichText,
  useStoryContext,
} from '@vendure/components/shared/client'
import { Button, useToast } from '@vendure/components/ui'
import { usePathname } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { FaFacebook, FaLinkedin } from 'react-icons/fa6'
import { HiClipboardCopy } from 'react-icons/hi'
import { useIntl } from 'react-intl'
import slugify from 'slugify'
import type { StoryblokStory } from 'storyblok-generate-ts'

function useHeadsObserver() {
  const observer = useRef<any>()
  const [activeId, setActiveId] = useState('')

  useEffect(() => {
    const handleObsever = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id)
        }
      })
    }

    observer.current = new IntersectionObserver(handleObsever, {
      rootMargin: '-20% 0% -35% 0px',
    })

    const elements = document.querySelectorAll('h2, h3, h4')
    elements.forEach((elem) => observer.current.observe(elem))
    return () => observer.current?.disconnect()
  }, [])

  return { activeId }
}

interface IArticle {
  blok: ArticleStoryblok
}

const _enrichArticleContent = ({ content }: any) => {
  for (const item of content) {
    if (item.type === 'heading') {
      const text = item.content?.at(0)?.text

      if (text) {
        const slug = slugify(text, { lower: true })
        item.attrs.id = slug
      }
    }
  }

  return {
    content,
    type: 'doc',
  }
}

export function Article({ blok }: IArticle) {
  const intl = useIntl()
  const { toast } = useToast()
  const { story } = useStoryContext()
  const content = blok
  const author = content.author as StoryblokStory<PersonStoryblok>
  const { content: category } =
    content.category as StoryblokStory<ArticleCategoryStoryblok>
  const path = usePathname()
  const articleUrl = process.env.NEXT_PUBLIC_BASE_HOST_AND_SCHEME! + path

  const shareUrls = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${articleUrl}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${articleUrl}`,
  }

  function copyLinkToClipboard() {
    toast({
      title: intl.formatMessage({ id: 'general.copied_to_clipboard' }),
      variant: 'success',
    })
    navigator.clipboard.writeText(articleUrl)
  }

  const articleContent = _enrichArticleContent(content.content)

  return (
    <div className="pt-nav-height container mx-auto px-4 md:px-0">
      <div className="mx-auto flex flex-col items-center text-center md:pt-40 lg:max-w-screen-md">
        <div className=" flex gap-4">
          {content.category && <PreTitle>{category.name}</PreTitle>}
        </div>
        <Heading
          as={'h1'}
          className="my-4 text-3xl md:text-5xl"
        >
          {content.title}
        </Heading>
        <p className="text-sm font-medium">
          <FormattedDate
            format={'MMMM DD, YYYY'}
            date={story?.first_published_at ?? undefined}
          />
        </p>
      </div>
      <div className="mx-auto lg:max-w-[80%]">
        {content.featuredImage?.filename && (
          <GradientCard
            outerProps={{
              className: 'my-10 w-full relative',
            }}
          >
            <CustomImage
              width={1000}
              height={600}
              priority={true}
              src={content.featuredImage.filename}
              alt={content.featuredImage.alt ?? content.title ?? ''}
              className="aspect-video h-auto w-full object-cover object-center"
            />
          </GradientCard>
        )}
      </div>
      <div className="mb:my-20 mx-auto my-6 flex w-full flex-col items-center justify-between gap-4 md:mt-0 md:mt-10 md:w-auto md:flex-row lg:max-w-screen-md">
        {author.uuid && <HorizontalPersonCard person={author} />}
        <div className="flex w-full items-center justify-center gap-2 md:w-auto md:justify-end">
          <Button
            onClick={copyLinkToClipboard}
            variant={'secondary'}
          >
            <HiClipboardCopy className="mr-2"></HiClipboardCopy>
            {intl.formatMessage({
              id: 'blog_article.actions.copy_link',
            })}
          </Button>
          <Button
            variant={'secondary'}
            asChild
          >
            <CustomLink href={shareUrls.facebook}>
              <FaFacebook />
            </CustomLink>
          </Button>
          <Button
            variant={'secondary'}
            asChild
          >
            <CustomLink href={shareUrls.linkedin}>
              <FaLinkedin />
            </CustomLink>
          </Button>
        </div>
      </div>
      <div className="prose-lg lg:prose-xl mx-auto text-white lg:max-w-screen-md">
        {content.excerpt}
      </div>
      <hr className="mx-auto my-4 max-w-screen-lg border-white/10 lg:my-8" />
      <RichText
        containerProps={{
          className:
            'mb-5 lg:mx-auto lg:max-w-screen-md prose lg:prose-lg prose-slate',
        }}
        document={articleContent}
      />
    </div>
  )
}
