'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import type { ClientLogoSliderStoryblok } from '@vendure/codegen/storyblok'
import { CustomImage } from '@vendure/components/shared/client'
import { getWhiteColorFilter } from '@vendure/helpers'
import { motion } from 'framer-motion'

interface IClientLogoSlider {
  blok: ClientLogoSliderStoryblok
}

export function ClientLogoSlider({ blok }: IClientLogoSlider) {
  return (
    <div
      className="w-full overflow-hidden"
      {...storyblokEditable(blok)}
    >
      <motion.div
        animate={{ x: [0, -1100] }}
        className="relative flex items-center  justify-center whitespace-nowrap"
        transition={{
          duration: 15,
          repeat: Infinity,
          ease: 'linear',
          repeatType: 'loop',
        }}
      >
        {blok.logos?.map((item) => {
          return (
            <CustomImage
              width={400}
              height={200}
              key={item.id}
              className="mx-20 h-[30px] w-[200px] object-contain object-center"
              style={{
                filter: getWhiteColorFilter(),
              }}
              src={item.filename}
              alt={item.alt ?? ''}
            />
          )
        })}
      </motion.div>
    </div>
  )
}
