'use client'

import { ClientLogosStoryblok } from '@vendure/codegen/storyblok'
import { TitleWithAccent } from '@vendure/components/shared/client'
import { Button } from '@vendure/components/ui'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { FiArrowRight } from 'react-icons/fi'

interface ClientLogosProps {
  blok: ClientLogosStoryblok
}

export function ClientLogos({ blok }: ClientLogosProps) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div className="mx-auto flex max-w-screen-sm flex-col justify-center">
      <TitleWithAccent
        containerProps={{ className: 'text-center text-xl' }}
        title={blok.title}
      ></TitleWithAccent>
      <div
        className="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="px-10 py-10 md:mx-auto md:px-0 md:py-20">
          <div className="grid grid-cols-2 items-center justify-center gap-12 md:grid-cols-3 md:gap-16">
            {blok.logos?.map((item) => {
              return (
                <motion.div
                  key={item.id}
                  animate={{ filter: isHovered ? 'blur(5px)' : 'blur(0)' }}
                  className="flex items-center justify-center "
                >
                  <Image
                    unoptimized={true}
                    width={200}
                    height={100}
                    className="h-8 w-auto brightness-0 invert transition-all will-change-auto md:h-10"
                    src={item.filename}
                    alt={item.alt ?? ''}
                  ></Image>
                </motion.div>
              )
            })}
          </div>
        </div>
        <AnimatePresence>
          {isHovered && blok.linkTarget?.cached_url && (
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              className="bg-dark/70 absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
            >
              <Button
                asChild
                variant="lead"
              >
                <Link href={blok.linkTarget?.cached_url}>
                  {blok.linkText} <FiArrowRight className="ml-2"></FiArrowRight>
                </Link>
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}
