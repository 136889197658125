'use client'

import type { ContactFormStoryblok } from '@vendure/codegen/storyblok'
import {
  ContactForm as ContactFormComponent,
  CustomImage,
  CustomLink,
  RichText,
  TitleWithAccent,
} from '@vendure/components/shared/client'
import { Button } from '@vendure/components/ui'
import { Suspense } from 'react'
import { FiArrowUpRight } from 'react-icons/fi'
import { useIntl } from 'react-intl'

interface ContactFormProps {
  blok: ContactFormStoryblok
}

export function ContactForm({ blok }: ContactFormProps) {
  const intl = useIntl()
  return (
    <div className="grid grid-cols-1 gap-10 md:grid-cols-2  lg:grid-cols-[2fr_1.5fr]">
      <div>
        <TitleWithAccent
          title={blok.title}
          containerProps={{
            className: 'mb-2 md:mb-6 mt-6 md:mt-0 text-3xl md:text-6xl',
          }}
        />
        <RichText
          document={blok.content}
          containerProps={{ className: 'mb-4 md:mb-10 md:text-lg' }}
        />
        {blok.officeImage?.filename && (
          <CustomImage
            alt={blok.officeImage.alt ?? 'Vendure office'}
            width={800}
            height={400}
            src={blok.officeImage.filename}
            className="h-[200px] w-full rounded-md object-cover md:h-[300px]"
          />
        )}
        <span className="mb-1 mt-6 block font-bold text-white">
          {intl.formatMessage({ id: 'contact_form.label.office_address' })}
        </span>
        <RichText
          document={blok.officeAddress}
          containerProps={{ className: 'mb-4 text-xl' }}
        />
        {blok.mapsLink?.url && (
          <Button
            asChild
            variant="lead"
          >
            <CustomLink
              href={blok.mapsLink.url}
              target="_blank"
            >
              {intl.formatMessage({ id: 'contact_form.label.show_on_map' })}{' '}
              <FiArrowUpRight className="ml-1 h-5 w-5"></FiArrowUpRight>
            </CustomLink>
          </Button>
        )}
      </div>
      <Suspense>
        <ContactFormComponent />
      </Suspense>
    </div>
  )
}
