'use client'

import {
  ConfAgendaSlotStoryblok,
  ConfAgendaStoryblok,
  PersonStoryblok,
} from '@vendure/codegen/storyblok'
import {
  Agenda,
  AgendaSlot,
  GradientCard,
  Heading,
  RichText,
} from '@vendure/components/shared/client'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@vendure/components/ui/ui/dialog'
import Image from 'next/image'
import { useMemo, useState } from 'react'
import { StoryblokStory } from 'storyblok-generate-ts'

interface ConfAgendaProps {
  blok: ConfAgendaStoryblok
}

export function ConfAgenda({ blok }: ConfAgendaProps) {
  const [detailSlot, setDetailSlot] = useState<ConfAgendaSlotStoryblok | null>(
    null,
  )

  const start = useMemo(() => {
    return blok.start ? new Date(blok.start) : new Date()
  }, [blok])

  const end = useMemo(() => {
    return blok.end ? new Date(blok.end) : new Date()
  }, [blok])

  const lanes = useMemo(() => {
    return blok.lanes?.map((lane) => {
      return {
        title: lane.title,
        date: lane.date ? new Date(lane.date) : undefined,
        slots: lane.slots?.map((slot) => {
          return {
            start: slot.start ? new Date(slot.start) : new Date(),
            end: slot.end ? new Date(slot.end) : new Date(),
            title: slot.title,
            subTitle: slot.subtitle,
            active: slot._uid === detailSlot?._uid,
            onClick: slot.detailsAvailable
              ? () => {
                  setDetailSlot(slot)
                }
              : undefined,
            payment:
              slot.paymentLink && slot.paymentLink?.cached_url !== ''
                ? {
                    link: slot.paymentLink?.cached_url,
                    seats: slot.availableSeats,
                    price: slot.pricePerSeat,
                  }
                : undefined,
            speakers: slot.people
              ?.filter((item) => typeof item != 'string')
              .map((item) => {
                const person = (item as StoryblokStory<PersonStoryblok>).content
                return {
                  image: person.image?.filename,
                  name: person.name,
                  role: person.role,
                }
              }),
          } as AgendaSlot
        }),
      }
    })
  }, [blok, detailSlot])

  return (
    <div className="mx-auto max-w-screen-lg">
      <Agenda
        lanes={lanes}
        startHour={start}
        endHour={end}
      ></Agenda>
      <Dialog
        open={detailSlot != undefined}
        onOpenChange={(val) => {
          if (!val) {
            setDetailSlot(null)
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle asChild>
              <Heading className="mb-4 text-2xl">{detailSlot?.title}</Heading>
            </DialogTitle>
            <DialogDescription>
              <RichText document={detailSlot?.description}></RichText>
              {detailSlot &&
                detailSlot.people &&
                detailSlot.people.length > 0 && (
                  <div className="mt-6">
                    <Heading className="text-lg">Speakers</Heading>
                    <div className="mt-4 flex flex-col gap-4">
                      {detailSlot.people.map((item, i) => {
                        const person = (item as StoryblokStory<PersonStoryblok>)
                          .content
                        return (
                          <div className="flex items-center gap-2">
                            <GradientCard
                              outerProps={{
                                className:
                                  'h-16 w-16 overflow-hidden rounded-full',
                              }}
                            >
                              {person.image && (
                                <Image
                                  width={100}
                                  height={100}
                                  className="h-full w-full object-cover object-center"
                                  src={person.image.filename}
                                  alt={person.name ?? ''}
                                ></Image>
                              )}
                            </GradientCard>
                            <div>
                              <p className="font-semibold text-white">
                                {person.name}
                              </p>
                              <p>{person.role}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  )
}
