'use client'

import { StoryblokComponent } from '@storyblok/react/rsc'
import type {
  CaseStudyStoryblok,
  CustomerStoryblok,
  RichtextStoryblok,
} from '@vendure/codegen/storyblok'
import {
  CustomImage,
  CustomLink,
  GradientCard,
} from '@vendure/components/shared/client'
import { HiGlobe, HiTag } from 'react-icons/hi'
import type { StoryblokStory } from 'storyblok-generate-ts'
import { PageHero } from './PageHero'

interface ICaseStudy {
  blok: CaseStudyStoryblok
}

export function CaseStudy({ blok }: ICaseStudy) {
  const customer = blok.customer as
    | StoryblokStory<CustomerStoryblok>
    | undefined
  // TODO: Re-think the implementation of the detail page.
  return (
    <div>
      <PageHero
        blok={{
          _uid: `${blok._uid}pageHero`,
          component: 'pageHero',
          title: [
            {
              _uid: `${blok._uid}title`,
              component: 'titleWithAccent',
              normal: blok.name,
              accentPosition: 'second',
            },
          ],
          text: blok.shortDescription as unknown as RichtextStoryblok,
        }}
      />
      <div className="border-y border-white/20 py-5">
        <div className="container">
          <div className="flex flex-col items-center justify-center gap-1 md:flex-row md:gap-10">
            {customer && (
              <div className="flex items-center gap-2">
                {customer.content.logo?.filename && (
                  <GradientCard
                    outerProps={{
                      className:
                        'relative aspect-square h-12 bg-transparent w-12 mr-2 rounded-full',
                    }}
                  >
                    <CustomImage
                      fill
                      style={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                        padding: '8px',
                        filter: 'brightness(0) invert(1)',
                      }}
                      src={customer.content.logo.filename}
                      alt={customer.content.name ?? ''}
                    />
                  </GradientCard>
                )}
                <div className="text-lg font-semibold text-white">
                  {customer.content.name}
                </div>
              </div>
            )}
            {blok.builtByLink?.cached_url && (
              <CustomLink
                className="hover:bg-primary-400 underline"
                href={blok.builtByLink.cached_url}
              >
                Built by {blok.builtBy}
              </CustomLink>
            )}
            {!blok.builtByLink?.cached_url && (
              <span>Built by {blok.builtBy}</span>
            )}
            <div className="flex gap-2">
              <div className="flex items-center">
                <HiTag className="text-primary-400 mr-1" />
                {blok.category}
              </div>
              <div className="flex items-center">
                <HiGlobe className="text-primary-400 mr-1" />
                {blok.location}
              </div>
            </div>
            {/* TODO: figure out a way to get the story object in here
           <div className="flex gap-2">
              {story?.tag_list.map((item) => {
                return <Badge key={item}>{item}</Badge>
              })}
            </div>*/}
          </div>
        </div>
      </div>
      {blok.body?.map((item) => (
        <StoryblokComponent
          key={item._uid}
          blok={item}
        />
      ))}
    </div>
  )
}
